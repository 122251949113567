import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import cn from "classnames"
import tw from "twin.macro"
import get from "lodash/get"
import {
  FaChevronDown,
  FaDollarSign,
  FaGlobe,
  FaLanguage,
  FaPen,
  FaRegUserCircle,
  FaUserCheck,
} from "react-icons/fa"
import { Elements, RichText } from "prismic-reactjs"
import { MdMenu, MdClose } from "react-icons/md"
import { motion } from "framer-motion"
import groupBy from "lodash/groupBy"

import { linkResolver } from "../../prismic.config"
import { LinkResolver } from "./TextRender"
import TextRender from "./TextRender"
import useIntersectionObserver from "./useIntersectionObserver"
import Logo from "../icons/logo"

export default function Header({
  pageContext,
  landingpage,
  transparentNavigation,
}) {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)
  const menuRef = React.useRef()
  const menuTrigger = React.useRef()
  useEffect(() => {
    function outside(e) {}

    if (typeof window !== "undefined") {
      document.addEventListener("click", outside)
      return () => {
        document.removeEventListener("click", outside)
      }
    }
  }, [])
  const { configurations } = useStaticQuery(graphql`
    {
      configurations: allPrismicConfiguration {
        nodes {
          lang
          data {
            login_buttons {
              raw
            }
            navigation {
              top_level_link {
                raw
              }
              submenu {
                text
                raw
              }
            }
            body {
              ... on PrismicConfigurationBodyMegaMenu {
                id
                primary {
                  split_items_into_columns
                  top_level_link {
                    raw
                  }
                }
                items {
                  icon
                  group
                  submenu_item {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const configuration = configurations.nodes.find(
    (n) => n && pageContext && n.lang == pageContext.lang
  )
  const navigation = get(configuration, "data.navigation")
  const shadowTrigger = React.useRef()
  const [isSticky, setIsSticky] = useState(false)
  useIntersectionObserver({
    threshold: 0,
    target: shadowTrigger,
    onIntersect: () => {
      setIsSticky(false)
    },
    onLeave: () => {
      setIsSticky(true)
    },
  })
  return (
    <header>
      <div
        className="h-2 absolute top-0 hidden lg:block intersection-trigger "
        ref={shadowTrigger}
      ></div>
      <div
        className={cn("absolute lg:fixed top-0 left-0 z-50 w-full   ", {
          "lg:shadow-sm  bg-white ": isSticky,
          "bg-white lg:bg-transparent": transparentNavigation && !isSticky,
          "bg-white": !transparentNavigation,
        })}
      >
        <div className="container lg:max-w-6xl py-4 relative">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0 block pt-1">
              <Link
                to={linkResolver({
                  type: "page",
                  lang: pageContext.lang,
                  uid: "homepage",
                })}
                className=""
              >
                <Logo></Logo>
                <span className="sr-only">greatcontent</span>
              </Link>
            </div>
            <div
              className={cn("items-center  lg:space-x-8 ", {
                "ml-auto flex": landingpage,
                "lg:flex tablet:hidden ": !landingpage,
              })}
              css={css`
                a {
                  ${tw`hover:text-primary`}
                }
              `}
            >
              {landingpage && (
                <div className="   mr-6">
                  {landingpage.map((item, key) => (
                    <TextRender text={item.link.raw} key={key} />
                  ))}
                  {/* <pre>{JSON.stringify(landingpage, null, 2)}</pre> */}
                </div>
              )}
              {!landingpage &&
                get(configuration, "data.body", []).map((item, key) => {
                  return (
                    <div key={key}>
                      <MegaMenuItem data={item} />
                    </div>
                  )
                })}
            </div>
            <div
              className="flex items-center space-x-2"
              css={css`
                a.login_button {
                  ${tw`inline-flex items-center justify-center min-w-0 px-4 py-2 text-sm leading-snug text-white border rounded-sm bg-primary border-primary phone:py-2`}
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                }
              `}
            >
              {!landingpage && (
                <div className="items-center hidden md:flex">
                  {" "}
                  <LanguageSwitcher pageContext={pageContext} />
                </div>
              )}
              {!landingpage && (
                <div className="phone:hidden">
                  <Link
                    className="secondary-button "
                    to={linkResolver({
                      type: "page",
                      lang: pageContext.lang,
                      uid: "forkpage",
                    })}
                  >
                    Start now
                  </Link>
                </div>
              )}
              <div className={cn({ "phone:hidden": !landingpage })}>
                <Dropdown
                  trigger={
                    <a href="#" className="login_button">
                      Login
                    </a>
                  }
                  content={
                    <TextRender
                      text={get(configuration, "data.login_buttons.raw")}
                      serializer={(type, element, content, children, key) => {
                        switch (type) {
                          case "paragraph":
                            return (
                              <span
                                className="hover:text-primary"
                                style={{ minWidth: 70 }}
                              >
                                {children}
                              </span>
                            )

                          default:
                            break
                        }
                      }}
                    />
                  }
                />
              </div>

              {!landingpage && (
                <div className="hidden tablet:block ">
                  <div
                    className="px-3 -mr-3 cursor-pointer "
                    ref={menuTrigger}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setMobileMenuIsOpen(!mobileMenuIsOpen)
                    }}
                  >
                    {!mobileMenuIsOpen && <MdMenu className="w-8 h-8" />}
                    {mobileMenuIsOpen && <MdClose className="w-8 h-8" />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: "-100%" }}
        animate={
          mobileMenuIsOpen
            ? {
                opacity: 1,
                y: 0,
                transition: {
                  ease: "easeOut",
                },
              }
            : {
                opacity: 0,
                y: "-100%",
                transition: {
                  ease: "easeIn",
                },
              }
        }
        className="absolute top-0 left-0 z-40 hidden w-full max-h-screen pt-20 pb-10 overflow-y-auto bg-white tablet:block filter-shadow"
        ref={menuRef}
      >
        <div className="container flex flex-col space-y-6">
          {" "}
          {navigation &&
            navigation.map((item, index) => {
              return <NavigationItem key={index} item={item} />
            })}
          <div className=" pt-8 md:hidden">
            <LanguageSwitcherMobile pageContext={pageContext} />
            <LoginMobile
              configuration={configuration}
              pageContext={pageContext}
            />
          </div>
        </div>
      </motion.div>
    </header>
  )
}
function Dropdown({ trigger, content }) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = React.useRef()
  useEffect(() => {
    function outside(e) {
      if (
        (ref.current && !ref.current.contains(e.target)) ||
        e.tagName === "LI"
      ) {
        setIsOpen(false)
      }
    }

    if (typeof window !== "undefined") {
      document.addEventListener("click", outside)
      return () => {
        document.removeEventListener("click", outside)
      }
    }
  }, [])
  return (
    <div
      ref={ref}
      className="relative z-50 inline-flex items-center cursor-pointer tablet:flex-wrap group"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      {trigger}

      <>
        <motion.div
          initial={{ opacity: 0, pointerEvents: "none" }}
          animate={
            isOpen
              ? { opacity: 1, pointerEvents: "auto" }
              : { opacity: 0, pointerEvents: "none" }
          }
          className="absolute right-1/3 z-50   w-4 h-4 transform rotate-45 bg-white border-t border-l  block top-12 border-light-2 "
        ></motion.div>
      </>

      <motion.div
        initial={{
          opacity: 0,
          paddingTop: "0rem",
          paddingBottom: "0rem",
          height: 0,
          pointerEvents: "none",
        }}
        animate={
          isOpen
            ? {
                opacity: 1,
                height: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                pointerEvents: "auto",
                transition: {
                  ease: "easeOut",
                },
              }
            : {
                opacity: 0,
                height: 0,
                paddingTop: "0rem",
                paddingBottom: "0rem",
                pointerEvents: "none",
                transition: {
                  ease: "easeIn",
                },
              }
        }
        onClick={(e) => {
          e.stopPropagation()
        }}
        className={cn(
          " absolute    z-40 top-14 right-0 px-4 overflow-hidden bg-white     border border-light-2  rounded-md grid gap-x-6 gap-y-1 dropdown  filter-shadow  "
        )}
        css={css`
          grid-template-columns: min-content;
          min-width: min-content;
        `}
      >
        {content}
      </motion.div>
    </div>
  )
}
function NavigationItem({ item }) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = React.useRef()
  useEffect(() => {
    function outside(e) {
      if (
        (ref.current && !ref.current.contains(e.target)) ||
        e.tagName === "LI"
      ) {
        setIsOpen(false)
      }
    }

    if (typeof window !== "undefined") {
      document.addEventListener("click", outside)
      return () => {
        document.removeEventListener("click", outside)
      }
    }
  }, [])
  return (
    <div
      ref={ref}
      className="relative z-50 inline-flex items-center cursor-pointer tablet:flex-wrap group"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <TextRender text={item.top_level_link.raw} type="navigation" />
      {item.submenu.text !== "" && (
        <>
          {" "}
          <FaChevronDown
            className={cn(
              " w-4  h-4   ml-4 transform transition-all duration-200",
              {
                "rotate-0": !isOpen,
                "-rotate-180": isOpen,
              }
            )}
          />
          <motion.div
            initial={{ opacity: 0, pointerEvents: "none" }}
            animate={
              isOpen
                ? { opacity: 1, pointerEvents: "auto" }
                : { opacity: 0, pointerEvents: "none" }
            }
            className="absolute right-0 z-50 hidden w-4 h-4 transform rotate-45 bg-white border-t border-l lg:block top-8 border-light-2 "
          ></motion.div>
        </>
      )}
      {item.submenu.text !== "" && (
        <motion.div
          initial={{
            opacity: 0,
            paddingTop: "0rem",
            paddingBottom: "0rem",
            height: 0,
            pointerEvents: "none",
          }}
          animate={
            isOpen
              ? {
                  opacity: 1,
                  height: "auto",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  pointerEvents: "auto",
                  transition: {
                    ease: "easeOut",
                  },
                }
              : {
                  opacity: 0,
                  height: 0,
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                  pointerEvents: "none",
                  transition: {
                    ease: "easeIn",
                  },
                }
          }
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={cn(
            "lg:absolute    z-40 top-10 px-4 overflow-hidden bg-white   rounded-md grid gap-x-6 gap-y-1 dropdown  tablet:w-full"
          )}
          css={css`
            li {
              ${tw`my-2`}
            }
            strong {
              ${tw`font-normal text-light-text`}
            }
          `}
        >
          <TextRender text={item.submenu.raw} type={"submenu"} />
        </motion.div>
      )}
    </div>
  )
}
function MegaMenuItem({ data }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const ref = React.useRef()

  useEffect(() => {
    function outside(e) {
      if (
        (ref.current && !ref.current.contains(e.target)) ||
        e.tagName === "LI"
      ) {
        setIsOpen(false)
      }
    }

    if (typeof window !== "undefined") {
      document.addEventListener("click", outside)
      return () => {
        document.removeEventListener("click", outside)
      }
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
    } else {
      setTimeout(() => {
        setIsVisible(false)
      }, 500)
    }
  }, [isOpen])

  const groups = groupBy(data.items, "group")
  const columns = Object.keys(groups).reduce((a, key) => {
    return a + Math.round(Math.ceil(groups[key].length / 4))
  }, 0)
  return (
    <>
      <motion.div
        ref={ref}
        className=" z-50 inline-flex items-center cursor-pointer tablet:flex-wrap group   "
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <TextRender text={data.primary.top_level_link.raw} type="navigation" />

        {data.items && data.items.length > 0 && (
          <span className="relative">
            {" "}
            <FaChevronDown
              className={cn(
                "w-3 h-3 ml-4 transform transition-all duration-200",
                {
                  "rotate-0": !isOpen,
                  "-rotate-180": isOpen,
                }
              )}
            />
            <motion.div
              initial={{ opacity: 0, pointerEvents: "none" }}
              animate={
                isOpen
                  ? { opacity: 1, pointerEvents: "auto" }
                  : { opacity: 0, pointerEvents: "none" }
              }
              className="absolute right-0 z-50 hidden w-4 h-4 transform rotate-45 bg-white border-t border-l lg:block top-8 border-light-2 "
            ></motion.div>
          </span>
        )}

        {isVisible && (
          <>
            {data.items && data.items.length > 0 && (
              <motion.div
                initial={{
                  opacity: 0,
                  paddingTop: "0rem",
                  paddingBottom: "0rem",
                  height: 0,
                  pointerEvents: "none",
                }}
                animate={
                  isOpen
                    ? {
                        opacity: 1,
                        height: "auto",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        pointerEvents: "auto",
                        transition: {
                          ease: "easeOut",
                        },
                      }
                    : {
                        opacity: 0,
                        height: 0,
                        paddingTop: "0rem",
                        paddingBottom: "0rem",
                        pointerEvents: "none",
                        transition: {
                          ease: "easeIn",
                        },
                      }
                }
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className={cn(
                  " absolute z-40  -mx-2   px-4  overflow-hidden bg-white lg:border border-light-2 lg:rounded-md grid gap-x-4 gap-y-1 dropdown  filter-shadow    max-w-screen-lg"
                )}
                css={css`
                  top: 70px;

                  ${columns > 4 && "left:50%;"}
                  transform: translateX(${columns > 4 ? "-50%" : "0"});
                  max-width: calc(100% + 1rem);
                  width: calc(1rem + 20% * ${columns});
                  padding-bottom: 20px !important;
                  grid-template-columns: repeat(${columns}, 1fr);
                  li {
                    ${tw`my-2`}
                  }
                  strong {
                    ${tw`font-normal text-light-text`}
                  }
                `}
              >
                {Object.keys(groups).map((key) => {
                  return (
                    <div
                      key={key}
                      className={cn({
                        "col-span-2": groups[key].length > 4,
                      })}
                    >
                      {key !== "null" && (
                        <div className="text-light-text mb-2 pl-2">{key}</div>
                      )}
                      <div
                        className={cn({
                          "grid gap-2  ": true,
                        })}
                        css={
                          groups[key].length > 4
                            ? css`
                                @media (min-width: 1024px) {
                                  grid-template-columns: repeat(2, 1fr);
                                }
                              `
                            : css`
                                grid-template-columns: repeat(1, 1fr);
                              `
                        }
                      >
                        {groups[key].map((item, i) => {
                          return (
                            <div
                              className="cursor-pointer text-light-text grid gap-2  maxw6  p-2 rounded border border-transparent "
                              style={{ fontSize: 13 }}
                              key={i}
                              tw="hover:border-primary hover:shadow "
                              onClick={(e) => {
                                let el = e.target.querySelector("a")
                                if (el) {
                                  el.click()
                                }
                              }}
                              css={css`
                                grid-template-columns: 1rem 1fr;
                                a {
                                  ${tw`text-base mb-1 whitespace-nowrap`}
                                }
                                .icon {
                                  ${tw`mt-1`}
                                }
                                a,
                                .icon {
                                  color: #000;
                                  display: block;
                                }
                                &:hover a,
                                &:hover .icon {
                                  ${tw`text-primary`}
                                }
                              `}
                            >
                              <div className="icon pt-0.5">
                                <Icon id={item.icon} />
                              </div>
                              <div>
                                <RichText
                                  render={item.submenu_item.raw}
                                  htmlSerializer={(
                                    type,
                                    element,
                                    content,
                                    children,
                                    key
                                  ) => {
                                    switch (type) {
                                      case Elements.paragraph:
                                        return <>{children}</>
                                      case Elements.hyperlink:
                                        return (
                                          <LinkResolver
                                            data={element.data}
                                            key={key}
                                          >
                                            {children}
                                          </LinkResolver>
                                        )
                                      default:
                                        break
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </motion.div>
            )}
          </>
        )}
      </motion.div>
    </>
  )
}

function LanguageSwitcher({ pageContext }) {
  const lang = get(pageContext, "lang")
  const alternate_languages = get(pageContext, "alternate_languages")
  const langs = {
    "en-us": "en",
    "de-de": "de",
    "fr-fr": "fr",
    "it-it": "it",
  }
  return (
    <div className="hidden md:block ">
      <Dropdown
        trigger={
          <span className="inline-flex items-center mr-4">
            {" "}
            <FaGlobe className="mr-2" />{" "}
            <span className="uppercase">{langs[lang]}</span>
            <FaChevronDown className="w-3 h-3 ml-2" />
          </span>
        }
        content={
          <>
            {Object.keys(langs).map((key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    const v = key
                    const alt =
                      alternate_languages &&
                      alternate_languages.find((d) => d.lang === v)
                    if (alt) {
                      navigate(linkResolver(alt))
                    } else {
                      navigate(
                        linkResolver({
                          type: "page",
                          lang: v,
                          uid: "homepage",
                        })
                      )
                    }
                  }}
                  className="uppercase hover:text-primary"
                >
                  {langs[key]}
                </div>
              )
            })}
          </>
        }
      ></Dropdown>
    </div>
  )
}

function LanguageSwitcherMobile({ pageContext }) {
  const [isOpen, setIsOpen] = useState(false)
  const lang = get(pageContext, "lang")
  const alternate_languages = get(pageContext, "alternate_languages")
  const langs = {
    "en-us": "en",
    "de-de": "de",
    "fr-fr": "fr",
    "it-it": "it",
  }
  const ref = React.useRef()
  useEffect(() => {
    function outside(e) {
      if (
        (ref.current && !ref.current.contains(e.target)) ||
        e.tagName === "LI"
      ) {
        setIsOpen(false)
      }
    }

    if (typeof window !== "undefined") {
      document.addEventListener("click", outside)
      return () => {
        document.removeEventListener("click", outside)
      }
    }
  }, [])
  return (
    <div
      ref={ref}
      className="relative z-50 inline-flex items-center cursor-pointer tablet:flex-wrap group border-t border-b border-light-2 w-full py-4"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <div
        className={cn("uppercase flex items-center text-xl", {
          "text-primary": isOpen,
        })}
      >
        <FaGlobe className="mr-3" />
        {langs[lang]}
      </div>
      <div>
        {" "}
        <FaChevronDown
          className={cn("w-4 h-4 ml-4 transform transition-all duration-200", {
            "rotate-0": !isOpen,
            "-rotate-180": isOpen,
          })}
        />
        <motion.div
          initial={{ opacity: 0, pointerEvents: "none" }}
          animate={
            isOpen
              ? { opacity: 1, pointerEvents: "auto" }
              : { opacity: 0, pointerEvents: "none" }
          }
          className="absolute right-0 z-50 hidden w-4 h-4 transform rotate-45 bg-white border-t border-l lg:block top-8 border-light-2 "
        ></motion.div>
      </div>
      <motion.div
        initial={{
          opacity: 0,
          paddingTop: "0rem",
          paddingBottom: "0rem",
          height: 0,
          pointerEvents: "none",
        }}
        animate={
          isOpen
            ? {
                opacity: 1,
                height: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                pointerEvents: "auto",
                transition: {
                  ease: "easeOut",
                },
              }
            : {
                opacity: 0,
                height: 0,
                paddingTop: "0rem",
                paddingBottom: "0rem",
                pointerEvents: "none",
                transition: {
                  ease: "easeIn",
                },
              }
        }
        onClick={(e) => {
          e.stopPropagation()
        }}
        className={cn(
          "lg:absolute    z-40 top-10 px-4 overflow-hidden bg-white    lg:border border-light-2 lg:rounded-md grid gap-x-6 gap-y-1 dropdown lg:filter-shadow tablet:w-full"
        )}
        css={css`
          @media (min-width: 1024px) {
            grid-auto-flow: column;
            grid-auto-columns: 200px;
            grid-template-rows: repeat(3, auto);
          }
          li {
            ${tw`my-2`}
          }
          strong {
            ${tw`font-normal text-light-text`}
          }
        `}
      >
        <ul>
          {Object.keys(langs).map((key) => {
            const v = key
            const alt =
              alternate_languages &&
              alternate_languages.find((d) => d.lang === key)
            let link = "/"
            if (alt) {
              link = linkResolver(alt)
            } else {
              link = linkResolver({
                type: "page",
                lang: v,
                uid: "homepage",
              })
            }
            return (
              <li key={key} className="text-xl">
                <Link to={link}>
                  <h5 className="font-semibold uppercase">{langs[key]}</h5>
                </Link>
              </li>
            )
          })}
        </ul>
      </motion.div>
    </div>
  )
}
function LoginMobile({ configuration, pageContext }) {
  const [isOpen, setIsOpen] = useState(false)
  const lang = get(pageContext, "lang")
  const alternate_languages = get(pageContext, "alternate_languages")
  const langs = {
    "en-us": "en",
    "de-de": "de",
    "fr-fr": "fr",
    "it-it": "it",
  }
  const ref = React.useRef()
  useEffect(() => {
    function outside(e) {
      if (
        (ref.current && !ref.current.contains(e.target)) ||
        e.tagName === "LI"
      ) {
        setIsOpen(false)
      }
    }

    if (typeof window !== "undefined") {
      document.addEventListener("click", outside)
      return () => {
        document.removeEventListener("click", outside)
      }
    }
  }, [])
  return (
    <div
      ref={ref}
      className="relative z-50 inline-flex items-center cursor-pointer tablet:flex-wrap group   w-full py-4"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <div
        className={cn("  flex items-center text-xl", {
          "text-primary": isOpen,
        })}
      >
        <FaRegUserCircle className="mr-3" />
        Login
      </div>
      <div>
        {" "}
        <FaChevronDown
          className={cn("w-4 h-4 ml-4 transform transition-all duration-200", {
            "rotate-0": !isOpen,
            "-rotate-180": isOpen,
          })}
        />
        <motion.div
          initial={{ opacity: 0, pointerEvents: "none" }}
          animate={
            isOpen
              ? { opacity: 1, pointerEvents: "auto" }
              : { opacity: 0, pointerEvents: "none" }
          }
          className="absolute right-0 z-50 hidden w-4 h-4 transform rotate-45 bg-white border-t border-l lg:block top-8 border-light-2 "
        ></motion.div>
      </div>
      <motion.div
        initial={{
          opacity: 0,
          paddingTop: "0rem",
          paddingBottom: "0rem",
          height: 0,
          pointerEvents: "none",
        }}
        animate={
          isOpen
            ? {
                opacity: 1,
                height: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                pointerEvents: "auto",
                transition: {
                  ease: "easeOut",
                },
              }
            : {
                opacity: 0,
                height: 0,
                paddingTop: "0rem",
                paddingBottom: "0rem",
                pointerEvents: "none",
                transition: {
                  ease: "easeIn",
                },
              }
        }
        onClick={(e) => {
          e.stopPropagation()
        }}
        className={cn(
          "lg:absolute    z-40 top-10 px-4 overflow-hidden bg-white    lg:border border-light-2 lg:rounded-md grid gap-x-6 gap-y-1 dropdown lg:filter-shadow tablet:w-full"
        )}
        css={css`
          @media (min-width: 1024px) {
            grid-auto-flow: column;
            grid-auto-columns: 200px;
            grid-template-rows: repeat(3, auto);
          }
          li {
            ${tw`my-2`}
          }
          strong {
            ${tw`font-normal text-light-text`}
          }
        `}
      >
        <ul>
          <TextRender
            text={get(configuration, "data.login_buttons.raw")}
            serializer={(type, element, content, children, key) => {
              switch (type) {
                case "paragraph":
                  return <li className="text-xl">{children}</li>

                default:
                  break
              }
            }}
          />
        </ul>
      </motion.div>
    </div>
  )
}

function Icon({ id }) {
  switch (id) {
    case "ShoppingCart":
      return (
        <svg
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0015 7.45508L14.103 2.58008C14.1733 2.22852 13.9155 1.87695 13.5405 1.87695H4.35303L4.14209 0.845703C4.07178 0.587891 3.8374 0.376953 3.57959 0.376953H1.18896C0.86084 0.376953 0.626465 0.634766 0.626465 0.939453V1.31445C0.626465 1.64258 0.86084 1.87695 1.18896 1.87695H2.80615L4.47021 9.93945C4.07178 10.1738 3.81396 10.5957 3.81396 11.0645C3.81396 11.791 4.3999 12.377 5.12646 12.377C5.82959 12.377 6.43896 11.791 6.43896 11.0645C6.43896 10.7129 6.2749 10.3848 6.04053 10.127H10.939C10.7046 10.3848 10.564 10.7129 10.564 11.0645C10.564 11.791 11.1499 12.377 11.8765 12.377C12.5796 12.377 13.189 11.791 13.189 11.0645C13.189 10.5488 12.8843 10.1035 12.439 9.89258L12.5562 9.33008C12.6499 8.97852 12.3687 8.62695 12.0171 8.62695H5.73584L5.57178 7.87695H12.439C12.6968 7.87695 12.9312 7.71289 13.0015 7.45508Z"
            fill="currentColor"
          />
        </svg>
      )
    case "Megaphone":
      return (
        <svg
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1265 6.00195C14.1265 5.46289 13.8218 4.9707 13.3765 4.71289V1.15039C13.3765 0.939453 13.2124 0.376953 12.6265 0.376953C12.439 0.376953 12.2749 0.447266 12.1577 0.564453L10.1421 2.1582C9.15771 2.95508 7.89209 3.37695 6.62646 3.37695H2.12646C1.28271 3.37695 0.626465 4.05664 0.626465 4.87695V7.12695C0.626465 7.9707 1.28271 8.62695 2.12646 8.62695H2.8999C2.87646 8.88477 2.85303 9.14258 2.85303 9.37695C2.85303 10.3145 3.06396 11.2051 3.4624 11.9785C3.57959 12.2363 3.8374 12.377 4.11865 12.377H5.85303C6.4624 12.377 6.8374 11.6973 6.4624 11.2051C6.0874 10.6895 5.85303 10.0801 5.85303 9.37695C5.85303 9.11914 5.8999 8.88477 5.94678 8.62695H6.62646C7.89209 8.62695 9.15771 9.07227 10.1421 9.86914L12.1577 11.4629C12.2749 11.5801 12.439 11.627 12.603 11.627C13.189 11.627 13.353 11.1113 13.353 10.877V7.31445C13.8218 7.05664 14.1265 6.56445 14.1265 6.00195ZM11.8765 9.33008L11.0796 8.69727C9.81396 7.68945 8.24365 7.12695 6.62646 7.12695V4.87695C8.24365 4.87695 9.81396 4.33789 11.0796 3.33008L11.8765 2.69727V9.33008Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Search":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4624 10.7598L10.1187 8.41602C10.0015 8.32227 9.86084 8.25195 9.72021 8.25195H9.34521C9.97803 7.43164 10.3765 6.40039 10.3765 5.25195C10.3765 2.58008 8.17334 0.376953 5.50146 0.376953C2.80615 0.376953 0.626465 2.58008 0.626465 5.25195C0.626465 7.94727 2.80615 10.127 5.50146 10.127C6.62646 10.127 7.65771 9.75195 8.50146 9.0957V9.49414C8.50146 9.63477 8.54834 9.77539 8.66553 9.89258L10.9858 12.2129C11.2202 12.4473 11.5718 12.4473 11.7827 12.2129L12.439 11.5566C12.6733 11.3457 12.6733 10.9941 12.4624 10.7598ZM5.50146 8.25195C3.8374 8.25195 2.50146 6.91602 2.50146 5.25195C2.50146 3.61133 3.8374 2.25195 5.50146 2.25195C7.14209 2.25195 8.50146 3.61133 8.50146 5.25195C8.50146 6.91602 7.14209 8.25195 5.50146 8.25195Z"
            fill="currentColor"
          />
        </svg>
      )

    case "People":
      return (
        <svg
          width="16"
          height="11"
          viewBox="0 0 16 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.12646 5.37695C6.55615 5.37695 7.75146 4.20508 7.75146 2.75195C7.75146 1.32227 6.55615 0.126953 5.12646 0.126953C3.67334 0.126953 2.50146 1.32227 2.50146 2.75195C2.50146 4.20508 3.67334 5.37695 5.12646 5.37695ZM6.90771 6.12695H6.72021C6.22803 6.36133 5.68896 6.50195 5.12646 6.50195C4.54053 6.50195 4.00146 6.36133 3.50928 6.12695H3.32178C1.82178 6.12695 0.626465 7.3457 0.626465 8.8457V9.50195C0.626465 10.1348 1.11865 10.627 1.75146 10.627H8.50146C9.11084 10.627 9.62646 10.1348 9.62646 9.50195V8.8457C9.62646 7.3457 8.40771 6.12695 6.90771 6.12695ZM11.8765 5.37695C13.1187 5.37695 14.1265 4.36914 14.1265 3.12695C14.1265 1.88477 13.1187 0.876953 11.8765 0.876953C10.6343 0.876953 9.62646 1.88477 9.62646 3.12695C9.62646 4.36914 10.6343 5.37695 11.8765 5.37695ZM13.0015 6.12695H12.9077C12.5796 6.24414 12.228 6.31445 11.8765 6.31445C11.5015 6.31445 11.1499 6.24414 10.8218 6.12695H10.7515C10.2593 6.12695 9.81396 6.26758 9.43896 6.50195C10.0015 7.11133 10.3765 7.93164 10.3765 8.8457V9.73633C10.3765 9.7832 10.353 9.83008 10.353 9.87695H14.5015C15.1108 9.87695 15.6265 9.38477 15.6265 8.75195C15.6265 7.32227 14.4312 6.12695 13.0015 6.12695Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Text":
      return (
        <svg
          width="12"
          height="11"
          viewBox="0 0 12 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.907715 7.62695H7.07178C7.23584 7.62695 7.37646 7.50977 7.37646 7.3457V6.43164C7.37646 6.26758 7.23584 6.12695 7.07178 6.12695H0.907715C0.743652 6.12695 0.626465 6.26758 0.626465 6.43164V7.3457C0.626465 7.50977 0.743652 7.62695 0.907715 7.62695ZM0.907715 1.62695H7.07178C7.23584 1.62695 7.37646 1.50977 7.37646 1.3457V0.431641C7.37646 0.267578 7.23584 0.126953 7.07178 0.126953H0.907715C0.743652 0.126953 0.626465 0.267578 0.626465 0.431641V1.3457C0.626465 1.50977 0.743652 1.62695 0.907715 1.62695ZM10.7515 3.12695H1.00146C0.790527 3.12695 0.626465 3.31445 0.626465 3.50195V4.25195C0.626465 4.46289 0.790527 4.62695 1.00146 4.62695H10.7515C10.939 4.62695 11.1265 4.46289 11.1265 4.25195V3.50195C11.1265 3.31445 10.939 3.12695 10.7515 3.12695ZM10.7515 9.12695H1.00146C0.790527 9.12695 0.626465 9.31445 0.626465 9.50195V10.252C0.626465 10.4629 0.790527 10.627 1.00146 10.627H10.7515C10.939 10.627 11.1265 10.4629 11.1265 10.252V9.50195C11.1265 9.31445 10.939 9.12695 10.7515 9.12695Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Screen":
      return (
        <svg
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0015 0.376953H1.75146C1.11865 0.376953 0.626465 0.892578 0.626465 1.50195V9.00195C0.626465 9.63477 1.11865 10.127 1.75146 10.127H6.25146L5.87646 11.252H4.18896C3.86084 11.252 3.62646 11.5098 3.62646 11.8145C3.62646 12.1426 3.86084 12.377 4.18896 12.377H10.564C10.8687 12.377 11.1265 12.1426 11.1265 11.8145C11.1265 11.5098 10.8687 11.252 10.564 11.252H8.87646L8.50146 10.127H13.0015C13.6108 10.127 14.1265 9.63477 14.1265 9.00195V1.50195C14.1265 0.892578 13.6108 0.376953 13.0015 0.376953ZM12.6265 8.62695H2.12646V1.87695H12.6265V8.62695Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Globe":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.50146 4.12695C8.17334 2.04102 7.35303 0.564453 6.43896 0.564453C5.50146 0.564453 4.70459 2.04102 4.35303 4.12695H8.50146ZM4.18896 6.37695C4.18896 6.91602 4.2124 7.4082 4.25928 7.87695H8.59521C8.64209 7.4082 8.66553 6.91602 8.66553 6.37695C8.66553 5.86133 8.64209 5.36914 8.59521 4.87695H4.25928C4.2124 5.36914 4.18896 5.86133 4.18896 6.37695ZM11.7827 4.12695C11.1265 2.55664 9.76709 1.31445 8.07959 0.822266C8.66553 1.61914 9.04053 2.81445 9.25146 4.12695H11.7827ZM4.7749 0.822266C3.0874 1.31445 1.72803 2.55664 1.07178 4.12695H3.60303C3.81396 2.81445 4.18896 1.61914 4.7749 0.822266ZM12.0405 4.87695H9.34521C9.39209 5.36914 9.43896 5.88477 9.43896 6.37695C9.43896 6.89258 9.39209 7.38477 9.34521 7.87695H12.0405C12.1577 7.4082 12.228 6.91602 12.228 6.37695C12.228 5.86133 12.1577 5.36914 12.0405 4.87695ZM3.43896 6.37695C3.43896 5.88477 3.4624 5.36914 3.50928 4.87695H0.813965C0.696777 5.36914 0.626465 5.86133 0.626465 6.37695C0.626465 6.91602 0.696777 7.4082 0.813965 7.87695H3.50928C3.4624 7.38477 3.43896 6.89258 3.43896 6.37695ZM4.35303 8.62695C4.70459 10.7363 5.50146 12.1895 6.43896 12.1895C7.35303 12.1895 8.17334 10.7363 8.50146 8.62695H4.35303ZM8.07959 11.9551C9.76709 11.4629 11.1265 10.2207 11.7827 8.62695H9.25146C9.06396 9.96289 8.66553 11.1582 8.07959 11.9551ZM1.07178 8.62695C1.72803 10.2207 3.0874 11.4629 4.7749 11.9551C4.18896 11.1582 3.81396 9.96289 3.60303 8.62695H1.07178Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Copy":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5015 0.376953H4.75146C4.11865 0.376953 3.62646 0.892578 3.62646 1.50195V8.25195C3.62646 8.88477 4.11865 9.37695 4.75146 9.37695H11.5015C12.1108 9.37695 12.6265 8.88477 12.6265 8.25195V1.50195C12.6265 0.892578 12.1108 0.376953 11.5015 0.376953ZM4.75146 10.127C3.69678 10.127 2.87646 9.30664 2.87646 8.25195V3.37695H1.75146C1.11865 3.37695 0.626465 3.89258 0.626465 4.50195V11.252C0.626465 11.8848 1.11865 12.377 1.75146 12.377H8.50146C9.11084 12.377 9.62646 11.8848 9.62646 11.252V10.127H4.75146Z"
            fill="currentColor"
          />
        </svg>
      )

    case "ThumbUp":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.06396 5.62695H1.18896C0.86084 5.62695 0.626465 5.88477 0.626465 6.18945V11.8145C0.626465 12.1426 0.86084 12.377 1.18896 12.377H3.06396C3.36865 12.377 3.62646 12.1426 3.62646 11.8145V6.18945C3.62646 5.88477 3.36865 5.62695 3.06396 5.62695ZM2.12646 11.4395C1.79834 11.4395 1.56396 11.2051 1.56396 10.877C1.56396 10.5723 1.79834 10.3145 2.12646 10.3145C2.43115 10.3145 2.68896 10.5723 2.68896 10.877C2.68896 11.2051 2.43115 11.4395 2.12646 11.4395ZM9.62646 2.29883C9.62646 0.564453 8.50146 0.376953 7.93896 0.376953C7.44678 0.376953 7.23584 1.31445 7.14209 1.73633C7.00146 2.25195 6.88428 2.76758 6.53271 3.11914C5.78271 3.89258 5.38428 4.85352 4.44678 5.76758C4.3999 5.83789 4.37646 5.9082 4.37646 5.97852V10.9941C4.37646 11.1348 4.49365 11.252 4.63428 11.2754C5.00928 11.2754 5.50146 11.4863 5.87646 11.6504C6.62646 11.9785 7.54053 12.377 8.66553 12.377H8.73584C9.74365 12.377 10.939 12.377 11.4077 11.6973C11.6187 11.416 11.6655 11.0645 11.5483 10.6426C11.9468 10.2441 12.1343 9.49414 11.9468 8.88477C12.3452 8.3457 12.3921 7.57227 12.1577 7.0332C12.439 6.75195 12.6265 6.30664 12.603 5.88477C12.603 5.1582 11.9937 4.50195 11.2202 4.50195H8.82959C9.01709 3.8457 9.62646 3.2832 9.62646 2.29883Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Paperplane":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7827 0.470703L0.907715 6.72852C0.48584 6.96289 0.532715 7.57227 0.95459 7.73633L3.4624 8.79102L10.189 2.86133C10.3062 2.74414 10.4937 2.9082 10.3765 3.04883L4.75146 9.91602V11.8145C4.75146 12.377 5.40771 12.5879 5.73584 12.1895L7.23584 10.3613L10.1421 11.6035C10.4702 11.7441 10.8687 11.5332 10.9155 11.1582L12.603 1.0332C12.6968 0.564453 12.1812 0.236328 11.7827 0.470703Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Amazon":
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.6499 3.19727C5.50146 3.24414 2.66553 3.57227 2.66553 5.96289C2.66553 8.51758 5.92334 8.63477 6.97803 6.9707C7.11865 7.20508 7.79834 7.83789 8.03271 8.07227L9.36865 6.75977C9.36865 6.75977 8.61865 6.15039 8.61865 5.51758V2.07227C8.61865 1.46289 8.03271 0.126953 5.97021 0.126953C3.90771 0.126953 2.82959 1.41602 2.82959 2.58789L4.54053 2.75195C4.91553 1.58008 5.80615 1.58008 5.80615 1.58008C6.76709 1.58008 6.6499 2.2832 6.6499 3.19727ZM6.6499 5.23633C6.6499 7.11133 4.68115 6.83008 4.68115 5.63477C4.68115 4.5332 5.85303 4.29883 6.6499 4.27539V5.23633ZM9.8374 9.05664C10.0249 8.82227 9.88428 8.70508 9.69678 8.77539C5.38428 10.8379 2.68896 9.12695 0.978027 8.07227C0.86084 8.00195 0.67334 8.0957 0.837402 8.25977C1.42334 8.96289 3.29834 10.627 5.73584 10.627C8.19678 10.627 9.6499 9.29102 9.8374 9.05664ZM10.7749 9.12695C10.9155 8.75195 11.0093 8.23633 10.9155 8.11914C10.7983 7.95508 10.2124 7.93164 9.8374 7.97852C9.4624 8.02539 8.8999 8.25977 8.97021 8.37695C8.99365 8.44727 9.04053 8.42383 9.29834 8.40039C9.55615 8.37695 10.2593 8.2832 10.4233 8.4707C10.564 8.6582 10.189 9.5957 10.1187 9.75977C10.0483 9.90039 10.1421 9.94727 10.2593 9.85352C10.3999 9.73633 10.6108 9.47852 10.7749 9.12695Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Paper":
      return (
        <svg
          width="10"
          height="13"
          viewBox="0 0 10 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.87646 3.56445V0.376953H1.18896C0.86084 0.376953 0.626465 0.634766 0.626465 0.939453V11.8145C0.626465 12.1426 0.86084 12.377 1.18896 12.377H9.06396C9.36865 12.377 9.62646 12.1426 9.62646 11.8145V4.12695H6.43896C6.11084 4.12695 5.87646 3.89258 5.87646 3.56445ZM7.37646 9.0957C7.37646 9.25977 7.23584 9.37695 7.09521 9.37695H3.15771C2.99365 9.37695 2.87646 9.25977 2.87646 9.0957V8.9082C2.87646 8.76758 2.99365 8.62695 3.15771 8.62695H7.09521C7.23584 8.62695 7.37646 8.76758 7.37646 8.9082V9.0957ZM7.37646 7.5957C7.37646 7.75977 7.23584 7.87695 7.09521 7.87695H3.15771C2.99365 7.87695 2.87646 7.75977 2.87646 7.5957V7.4082C2.87646 7.26758 2.99365 7.12695 3.15771 7.12695H7.09521C7.23584 7.12695 7.37646 7.26758 7.37646 7.4082V7.5957ZM7.37646 5.9082V6.0957C7.37646 6.25977 7.23584 6.37695 7.09521 6.37695H3.15771C2.99365 6.37695 2.87646 6.25977 2.87646 6.0957V5.9082C2.87646 5.76758 2.99365 5.62695 3.15771 5.62695H7.09521C7.23584 5.62695 7.37646 5.76758 7.37646 5.9082ZM9.62646 3.23633C9.62646 3.0957 9.55615 2.95508 9.4624 2.83789L7.16553 0.541016C7.04834 0.447266 6.90771 0.376953 6.76709 0.376953H6.62646V3.37695H9.62646V3.23633Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Growth":
      return (
        <svg
          width="13"
          height="10"
          viewBox="0 0 13 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.2515 8.37695H2.12646V1.25195C2.12646 1.06445 1.93896 0.876953 1.75146 0.876953H1.00146C0.790527 0.876953 0.626465 1.06445 0.626465 1.25195V9.12695C0.626465 9.54883 0.95459 9.87695 1.37646 9.87695H12.2515C12.439 9.87695 12.6265 9.71289 12.6265 9.50195V8.75195C12.6265 8.56445 12.439 8.37695 12.2515 8.37695ZM11.5015 1.62695H8.7124C8.22021 1.62695 7.9624 2.23633 8.31396 2.58789L9.0874 3.36133L7.37646 5.07227L5.64209 3.36133C5.36084 3.05664 4.86865 3.05664 4.5874 3.36133L2.97021 4.97852C2.82959 5.11914 2.82959 5.35352 2.97021 5.49414L3.50928 6.0332C3.6499 6.17383 3.88428 6.17383 4.0249 6.0332L5.12646 4.95508L6.8374 6.66602C7.11865 6.9707 7.61084 6.9707 7.89209 6.66602L10.1421 4.41602L10.9155 5.18945C11.2671 5.54102 11.853 5.2832 11.853 4.79102V2.00195C11.8765 1.81445 11.689 1.62695 11.5015 1.62695Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Plant":
      return (
        <svg
          width="15"
          height="11"
          viewBox="0 0 15 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.939 3.87695C13.2593 3.90039 10.7749 4.04102 8.92334 5.82227C8.1499 6.52539 7.68115 7.32227 7.37646 8.04883C7.04834 7.32227 6.57959 6.52539 5.80615 5.82227C3.95459 4.04102 1.47021 3.90039 0.790527 3.87695C0.696777 3.87695 0.603027 3.9707 0.626465 4.06445C0.626465 4.7207 0.790527 7.01758 2.68896 8.75195C4.65771 10.6504 6.62646 10.627 7.37646 10.627C8.12646 10.627 10.0718 10.6504 12.0405 8.75195C13.939 7.01758 14.103 4.7207 14.1265 4.06445C14.1265 3.9707 14.0327 3.87695 13.939 3.87695ZM7.35303 6.47852C7.65771 6.0332 8.00928 5.63477 8.40771 5.30664C8.85303 4.86133 9.32178 4.50977 9.81396 4.22852C9.41553 2.58789 8.59521 1.11133 7.54053 0.197266C7.44678 0.126953 7.28271 0.126953 7.18896 0.197266C6.13428 1.11133 5.31396 2.58789 4.91553 4.22852C5.40771 4.50977 5.87646 4.83789 6.29834 5.2832C6.72021 5.63477 7.07178 6.0332 7.35303 6.47852Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Gambling":
      return (
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5015 4.87695H11.7124C11.9937 5.58008 11.8765 6.42383 11.314 6.98633L8.12646 10.1738V11.252C8.12646 11.8848 8.61865 12.377 9.25146 12.377H14.5015C15.1108 12.377 15.6265 11.8848 15.6265 11.252V6.00195C15.6265 5.39258 15.1108 4.87695 14.5015 4.87695ZM11.8765 9.18945C11.5483 9.18945 11.314 8.95508 11.314 8.62695C11.314 8.32227 11.5483 8.06445 11.8765 8.06445C12.1812 8.06445 12.439 8.32227 12.439 8.62695C12.439 8.95508 12.1812 9.18945 11.8765 9.18945ZM10.7749 4.83008L6.67334 0.728516C6.22803 0.283203 5.50146 0.283203 5.05615 0.728516L0.95459 4.83008C0.509277 5.27539 0.509277 6.00195 0.95459 6.44727L5.05615 10.5488C5.50146 10.9941 6.22803 10.9941 6.67334 10.5488L10.7749 6.44727C11.2202 6.00195 11.2202 5.27539 10.7749 4.83008ZM2.87646 6.18945C2.54834 6.18945 2.31396 5.95508 2.31396 5.62695C2.31396 5.32227 2.54834 5.06445 2.87646 5.06445C3.18115 5.06445 3.43896 5.32227 3.43896 5.62695C3.43896 5.95508 3.18115 6.18945 2.87646 6.18945ZM5.87646 9.18945C5.54834 9.18945 5.31396 8.95508 5.31396 8.62695C5.31396 8.32227 5.54834 8.06445 5.87646 8.06445C6.18115 8.06445 6.43896 8.32227 6.43896 8.62695C6.43896 8.95508 6.18115 9.18945 5.87646 9.18945ZM5.87646 6.18945C5.54834 6.18945 5.31396 5.95508 5.31396 5.62695C5.31396 5.32227 5.54834 5.06445 5.87646 5.06445C6.18115 5.06445 6.43896 5.32227 6.43896 5.62695C6.43896 5.95508 6.18115 6.18945 5.87646 6.18945ZM5.87646 3.18945C5.54834 3.18945 5.31396 2.95508 5.31396 2.62695C5.31396 2.32227 5.54834 2.06445 5.87646 2.06445C6.18115 2.06445 6.43896 2.32227 6.43896 2.62695C6.43896 2.95508 6.18115 3.18945 5.87646 3.18945ZM8.87646 6.18945C8.54834 6.18945 8.31396 5.95508 8.31396 5.62695C8.31396 5.32227 8.54834 5.06445 8.87646 5.06445C9.18115 5.06445 9.43896 5.32227 9.43896 5.62695C9.43896 5.95508 9.18115 6.18945 8.87646 6.18945Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Travel":
      return (
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2515 9.87695H1.00146C0.790527 9.87695 0.626465 10.0645 0.626465 10.252V11.002C0.626465 11.2129 0.790527 11.377 1.00146 11.377H15.2515C15.439 11.377 15.6265 11.2129 15.6265 11.002V10.252C15.6265 10.0645 15.439 9.87695 15.2515 9.87695ZM2.50146 7.39258C2.64209 7.55664 2.85303 7.62695 3.06396 7.62695H6.13428C6.39209 7.62695 6.62646 7.58008 6.8374 7.46289L13.6577 4.01758C14.2671 3.68945 14.8296 3.24414 15.228 2.63477C15.6499 1.97852 15.6968 1.48633 15.5327 1.1582C15.3452 0.806641 14.9468 0.548828 14.1499 0.501953C13.4702 0.455078 12.7671 0.642578 12.1343 0.970703L9.8374 2.14258L4.70459 0.220703C4.63428 0.173828 4.56396 0.150391 4.47021 0.150391C4.3999 0.150391 4.32959 0.150391 4.28271 0.173828L2.73584 0.970703C2.47803 1.08789 2.43115 1.41602 2.61865 1.62695L6.2749 3.92383L3.86084 5.16602L2.17334 4.29883C2.10303 4.27539 2.03271 4.25195 1.98584 4.25195C1.91553 4.25195 1.84521 4.27539 1.79834 4.29883L0.837402 4.79102C0.603027 4.9082 0.532715 5.23633 0.720215 5.44727L2.50146 7.39258Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Carot":
      return (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.61084 4.05664C6.36865 3.44727 4.91553 3.82227 4.09521 4.83008L5.38428 6.11914C5.5249 6.25977 5.5249 6.49414 5.38428 6.6582C5.31396 6.72852 5.22021 6.75195 5.10303 6.75195C5.00928 6.75195 4.91553 6.72852 4.84521 6.6582L3.67334 5.46289L0.67334 11.627C0.603027 11.7676 0.603027 11.9316 0.67334 12.0957C0.790527 12.3535 1.11865 12.4707 1.37646 12.3301L4.49365 10.8066L3.34521 9.6582C3.20459 9.51758 3.20459 9.25977 3.34521 9.11914C3.50928 8.97852 3.74365 8.97852 3.88428 9.11914L5.22021 10.4551L7.61084 9.2832C8.17334 9.02539 8.64209 8.55664 8.94678 7.94727C9.6499 6.51758 9.04053 4.75977 7.61084 4.05664ZM9.76709 3.23633C10.2124 2.27539 9.97803 1.15039 9.06396 0.376953C7.86865 1.36133 7.82178 2.9082 8.87646 3.93945L9.06396 4.12695C10.0952 5.18164 11.6421 5.13477 12.6265 3.93945C11.853 3.02539 10.728 2.79102 9.76709 3.23633Z"
            fill="currentColor"
          />
        </svg>
      )

    case "Car":
      return (
        <svg
          width="13"
          height="10"
          viewBox="0 0 13 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3218 3.50195H10.939L10.5405 2.54102C10.1421 1.5332 9.18115 0.876953 8.10303 0.876953H5.12646C4.04834 0.876953 3.0874 1.5332 2.68896 2.54102L2.29053 3.50195H0.907715C0.720215 3.50195 0.57959 3.68945 0.626465 3.85352L0.76709 4.41602C0.790527 4.55664 0.907715 4.62695 1.04834 4.62695H1.51709C1.18896 4.9082 1.00146 5.30664 1.00146 5.75195V6.87695C1.00146 7.27539 1.14209 7.60352 1.37646 7.86133V9.12695C1.37646 9.54883 1.70459 9.87695 2.12646 9.87695H2.87646C3.2749 9.87695 3.62646 9.54883 3.62646 9.12695V8.37695H9.62646V9.12695C9.62646 9.54883 9.95459 9.87695 10.3765 9.87695H11.1265C11.5249 9.87695 11.8765 9.54883 11.8765 9.12695V7.86133C12.0874 7.60352 12.2515 7.27539 12.2515 6.87695V5.75195C12.2515 5.30664 12.0405 4.9082 11.7124 4.62695H12.2046C12.3218 4.62695 12.439 4.55664 12.4624 4.41602L12.603 3.85352C12.6499 3.68945 12.5093 3.50195 12.3218 3.50195ZM4.07178 3.10352C4.25928 2.6582 4.65771 2.37695 5.12646 2.37695H8.10303C8.57178 2.37695 8.97021 2.6582 9.15771 3.10352L9.62646 4.25195H3.62646L4.07178 3.10352ZM2.87646 6.87695C2.40771 6.87695 2.12646 6.5957 2.12646 6.12695C2.12646 5.68164 2.40771 5.37695 2.87646 5.37695C3.32178 5.37695 4.00146 6.05664 4.00146 6.50195C4.00146 6.94727 3.32178 6.87695 2.87646 6.87695ZM10.3765 6.87695C9.90771 6.87695 9.25146 6.94727 9.25146 6.50195C9.25146 6.05664 9.90771 5.37695 10.3765 5.37695C10.8218 5.37695 11.1265 5.68164 11.1265 6.12695C11.1265 6.5957 10.8218 6.87695 10.3765 6.87695Z"
            fill="currentColor"
          />
        </svg>
      )
    case "Pen":
      return <FaPen />
    case "Language":
      return <FaLanguage />
    case "Dollar":
      return <FaDollarSign />
    case "User-Check":
      return <FaUserCheck />
    default:
      return null
  }
}
